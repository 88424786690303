import { useStateContext } from 'application/store/ContextProvider';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { get } from 'application/api';
import { useApiMutation } from 'application/api';
import { TextField, Button, Autocomplete } from '@mui/material';
import { SelectMulti } from 'components/index';

const CreateCCR = ({ activeSDBIP, setModalOpen }) => {
    const [ccrFormData, setCcrFormData] = useState({
        name: "Employee Core Competency Requirements (CCR)",
        description: "",
        department_id: "",
        sdbip_id: activeSDBIP.id,
    });

    const { user } = useStateContext();

    const {
        isLoading,
        error,
        data: departments,
    } = useQuery("departments", () =>
        get("departments", user.organisation_id, null, user.token)
    );

    const addCcrMutation = useApiMutation(
        "ccrs",
        "post",
        user.organisation_id,
        user.token,
        "ccrs",
        (response) => {
                alert("CCR successfully created");
                setModalOpen(false);
        },
        (error) => {
            alert("Error adding CCR");
        }
    );


    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>An error has occurred: {error.message}</div>;
    }

    if (!departments.length) {
        return <div>No departments found</div>;
    }


    const handleChange = (e) => {
        setCcrFormData({
            ...ccrFormData,
            [e.target.name]: e.target.value,
        });
    }

    
  return (
    <div className="h-fit gap-4 flex-col bg-white min-w-[40vw] flex justify-center p-10 pt-20 rounded-md items-center">
    <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white text-xl bg-primary-blue font-light mb-8">
            Creating <span className="font-semibold">CCR for {activeSDBIP.name}</span>
    </h2> 
    <div className="absolute top-0 right-0">               
        <Button variant="text" color="error" onClick={() => setModalOpen(false)}>x</Button>
    </div>

    <TextField
        type="text"
        name="name"
        label="Name"
        onChange={handleChange}
        value={ccrFormData.name}
        fullWidth
    />

    <TextField
        type="text"
        name="description"
        label="Description"
        onChange={handleChange}
        value={ccrFormData.description}
        fullWidth
    />

    <SelectMulti
        sx={{ width: "100%", maxWidth: 500 }}
        data={departments}
        width={"100%"}
        multiple={false}
        label="Department"
        placeholder="Select Department."
        limitTags={1}
        handleChange={(e) => {
            setCcrFormData({
                ...ccrFormData,
                department_id: e,
            });
        }}
    />

    <Button
        variant="contained"
        color="primary"
        onClick={() => addCcrMutation.mutate(ccrFormData)}
    >
        Confirm
    </Button>
    </div>
  )
}

export default CreateCCR
import { useStateContext } from 'application/store/ContextProvider';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { get } from 'application/api';
import { useApiMutation } from 'application/api';
import { TextField, Button, Autocomplete } from '@mui/material';
import { SelectBasic, SelectMulti } from 'components/index';

const CreateRatingScaleLevel = ({ sdbip, setModalOpen }) => {
    console.log(sdbip)

    const [ratingScaleFormData, setRatingScaleFormData] = useState({
        level: "",
        name: "",
        description: "",
        color: "",
        sdbip_id: sdbip.id,
    });

    const { user } = useStateContext();

    const {
        isLoading,
        error,
        data: departments,
    } = useQuery("departments", () =>
        get("departments", user.organisation_id, null, user.token)
    );

    const addcompentencyMutation = useApiMutation(
        "rating_scales/employee",
        "post",
        user.organisation_id,
        user.token,
        "rating_scales",
        (response) => {
                alert("Rating scale successfully created");
                // setModalOpen(false);
        },
        (error) => {
            alert("Error adding Rating scale");
        }
    );


    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>An error has occurred: {error.message}</div>;
    }

    if (!departments.length) {
        return <div>No departments found</div>;
    }


    const handleChange = (e) => {
        if(e.target.name === 'level' ){
            e.target.value = parseInt(e.target.value)
        }
        setRatingScaleFormData({
            ...ratingScaleFormData,
            [e.target.name]: e.target.value,
        });
    }
    
  return (
    <div className="h-fit gap-4 flex-col bg-white min-w-[40vw] flex justify-center p-10 pt-20 rounded-md items-center">
    <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white text-xl bg-primary-blue font-light mb-8">
            Create <span className="font-semibold">Rating Scale level</span>
    </h2> 
    <div className="absolute top-0 right-0">               
        <Button variant="text" color="error" onClick={() => setModalOpen(false)}>x</Button>
    </div>

    <TextField
        name='level'
        label="Level"
        type="number"
        onChange={handleChange}
        value={ratingScaleFormData.level}
        fullWidth
    />

    <TextField
        type="text"
        name="name"
        label="Terminology"
        onChange={handleChange}
        value={ratingScaleFormData.name}
        fullWidth
    />
    <TextField
        type="text"
        name="description"
        label="Description"
        onChange={handleChange}
        value={ratingScaleFormData.description}
        fullWidth
    />

    <TextField
        type="text"
        name="color"
        label="Color"
        onChange={handleChange}
        value={ratingScaleFormData.color}
        fullWidth
    />

    {/* <SelectBasic
        data={[
            { id: "CMC", name: "Core Managerial Competency (CMC)" },
            { id: "COC", name: "Core Operational Competency (COC)" },
        ]}
        label="Type"
        fullWidth={true}
        required={true}
        value={ratingScaleFormData.type}
        onSelect={(e) => {
            setRatingScaleFormData({
                ...ratingScaleFormData,
                type: e.target.value,
            });
        }}
    /> */}

    <Button
        variant="contained"
        color="primary"
        onClick={() => addcompentencyMutation.mutate(ratingScaleFormData)}
    >
        Confirm
    </Button>
    </div>
  )
}

export default CreateRatingScaleLevel
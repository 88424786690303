import React from "react";
import { Scaffold, TabPager } from "components";
import { SetupPerformanceAgreement, SetupPDP } from "./view_components";
const tabs = [
  // {
  //   header: { text: "Personal Development Plan" },
  //   content: <SetupPDP/>,
  // },
  {
    header: { text: "Performance Agreements" },
    content: <SetupPerformanceAgreement />,
  },
  {
    header: { text: "Positions" },
    content: <div>Employee Position</div>,
  },
  {
    header: { text: "Align" },
    content: <div>Align</div>,
  },
  {
    header: { text: "Financial Plan" },
    content: <div>Outline Financial Plan</div>,
  },
  {
    header: { text: "User Permissions" },
    content: <div>User Permissions</div>,
  },
];

function SetupIDP() {
  return (
    <>
      <Scaffold moduleTitle="Employees" title="Setup" mb="">
        <TabPager content={tabs} />
      </Scaffold>
    </>
  );
}

export default SetupIDP;

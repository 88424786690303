import { Scaffold, TabPager } from 'components/index'
import React from 'react'
import { PerformanceAgreements } from './view_components';
import PerformancePlans from './view_components/tabs/PerformancePlans';

const tabs = [
    {
      header: { text: "Plans" },
      content: <PerformancePlans />,
    },
    {
        header: { text: "Agreements" },
        content: <PerformanceAgreements/>,
    },
    {
        header: { text: "Monitoring" },
        content: <div>Evaluation</div>,
    },
    {
        header: { text: "Evaluation" },
        content: <div>Evaluation</div>,
    },
    {
      header: { text: "Reporting" },
      content: <div>Reporting</div>,
    },
  ];

const Performance = () => {
  return (
    <Scaffold moduleTitle="Employee" title="Performance">
      <TabPager content={tabs} />
    </Scaffold>
  )
}

export default Performance
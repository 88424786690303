import React from 'react'
import SetupPdpItemsTable from '../components/SetupPdpItemsTable'
import { useQuery } from 'react-query'
import { get } from 'application/api'
import { useStateContext } from 'application/store/ContextProvider'
import PdpTable from '../components/PdpTable'

const PerformanceDevelopment = () => {
  // get active idp
  const { user } = useStateContext();
  const {
    isIdpLoading,
    errorIdp,
    data: activeIDP,
  } = useQuery("activeIDP", () =>
    get("idps/active", user.organisation_id, null, user.token)
  );

  if (isIdpLoading) {
    return <div>Loading...</div>;
  }
  if (errorIdp) {
    return <div>An error has occurred: {errorIdp.message}</div>;
  }

  return (
    <div className='pt-8'>
        {activeIDP && <PdpTable sdbip={activeIDP.sdbips[0]} />}
    </div>
  )
}

export default PerformanceDevelopment
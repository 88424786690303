import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AddOutlined } from "@mui/icons-material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useStateContext } from "application/store/ContextProvider";
import { useQuery } from "react-query";
import { get, update } from "application/api";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import CreatePDP from "../modals/CreatePDP";
// import CreatePerformancePlanItem from "../modals/CreatePerformancePlanItem";
import CreatePerformancePlan from "../modals/CreatePerformancePlan";
import KpiWeightingsTable from "./KpiWeightingsTable";
// import CreateKPI from "../modals/CreateKPI";


export default function PerformancePlansTable({
  showAlignInput = false,
  sdbip,
  idp
}) {
  const { brandColors } = useStateContext();
  const { setModalOpen, setModalContent } = useStateContext();
  // react-query get performance_plans
  const { user } = useStateContext();
  const {
    isLoading,
    error,
    data: performance_plans,
  } = useQuery("performance_plans", () =>
      get("performance_plans", user.organisation_id, { sdbip_id: sdbip.id }, user.token)
  );


  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  if (!performance_plans.length) {
    return <div>No Plans found, create new</div>;
  }
  console.log(performance_plans);
  console.log(sdbip);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              colSpan={6}
              style={{ backgroundColor: brandColors.blue, color: "white" }}
            >
              <div className="flex flex-row justify-between">
              <Typography variant="h6">
                Performance Plans
              </Typography>
              <IconButton
                aria-label="expand row"
                size="small"
                sx={{ ml: 1, color: "white"}}
                onClick={() => {
                  setModalOpen(true);
                  setModalContent(
                  <CreatePerformancePlan sdbip={sdbip} setModalOpen={setModalOpen} />
                  );
                }}
              >
                <AddOutlined />
              </IconButton>
              </div>

            </TableCell>
            {/* <TableCell /> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {performance_plans && performance_plans.map((performance_plan, index) => (
            <Row
              key={index}
              performance_plan={performance_plan}
              sdbip={sdbip}
              showAlignInput={showAlignInput}
              idp={idp}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row(props) {
  const { performance_plan, sdbip, idp } = props;
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { setModalOpen, setModalContent } = useStateContext();

  return (
    <React.Fragment>
      <TableRow >
        <TableCell style={{ width: 20 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" >
          <TableBody >
            <TableRow >
              <TableCell sx={{ borderBottom: "unset" }}>{performance_plan.id}</TableCell>
              <TableCell sx={{ borderBottom: "unset" }}>{performance_plan.name}</TableCell>
            </TableRow>
          </TableBody>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h7" fontWeight={500}>
                KPI weighting
              </Typography>
              <KpiWeightingsTable performance_plan={performance_plan} idpData={idp} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    short_code: PropTypes.string.isRequired,
    objectives: PropTypes.string.isRequired,
    parent_id: PropTypes.number.isRequired,
    idp_id: PropTypes.number.isRequired,
  }).isRequired,
};

import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { get } from 'application/api'
import { useStateContext } from 'application/store/ContextProvider'
import { SelectBasic } from 'components'
import PerformanceAgreementsTable from '../components/PerformanceAgreementsTable'

const PerformanceAgreements = () => {
  // get active idp
  const { user } = useStateContext();
  const [activeSDBIP, setActiveSDBIP] = useState({ id: 1, name: "2021/2022" });

  const {
    isIdpLoading,
    errorIdp,
    data: activeIDP,
  } = useQuery("activeIDP", () =>
    get("idps/active", user.organisation_id, null, user.token)
  );

  if (isIdpLoading) {
    return <div>Loading...</div>;
  }
  if (errorIdp) {
    return <div>An error has occurred: {errorIdp.message}</div>;
  }

  return (
    <div className='pt-8'>
      <div className="flex h-16 mb-2 flex-row justify-between w-full">
        <div className="flex flex-row w-3/12 md:w-4/12 items-end justify-end">
            {activeIDP.sdbips && <SelectBasic
              data={activeIDP.sdbips}
              label="Select Active SDBIP"
              defaultValue={activeIDP.sdbips[0].id || ""}
              onSelect={(e) => {
                console.log(e.target.value);
                setActiveSDBIP(
                  activeIDP.sdbips.find((s) => s.id === e.target.value)
                );
                console.log(activeSDBIP);
              }}
            />}
          </div>
        </div>
        {activeIDP && <PerformanceAgreementsTable sdbip={activeIDP.sdbips[0]} />}
    </div>
  )
}

export default PerformanceAgreements
import { get, useApiMutation } from "application/api";
import { useStateContext } from "application/store/ContextProvider";
import IDPSetupBox from "../components/SetupIDPBox";
import React, { useState } from "react";
// import { exportMultipleComponentsToPdf } from "application/utils";
import { Modal } from "components";
import { CreateIDP } from "..";
import { useQuery } from "react-query";
import CollapsibleMetricTable from "../components/SetupMetricTable";
import { SelectBasic } from "components";

const Metrics = () => {
  const { user } = useStateContext();
  console.log("Metrics user toke", user.token);
  const [addingType, setAddingType] = useState(null);
  const [addingObject, setAddingObject] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    isLoading,
    error,
    data: idps,
  } = useQuery("idps", () =>
    get("idps", user.organisation_id, null, user.token)
  );

  const {
    isPoliciesLoading,
    policiesError,
    data: policies,
  } = useQuery("policies", () =>
    get("policies", user.organisation_id, null, user.token)
  );

  const {
    isActiveIdpLoading,
    activeIdpError,
    data: activeIDP,
  } = useQuery("activeIDP", () =>
    get("idps/active", user.organisation_id, null, user.token)
  );

  const activeIdpMutation = useApiMutation(
    "idps/active",
    "put",
    user.organisation_id,
    user.token,
    "activeIDP",
    (response) => {
      console.log("active idp response", response);
    },
    (error) => {
      alert("Update failed!", error);
    }
  );

  if (isLoading || isPoliciesLoading || isActiveIdpLoading) {
    return <div>Loading...</div>;
  }
  if (error || policiesError || activeIdpError) {
    return <div>An error has occurred: {error.message}</div>;
  }

  console.log(idps);
  console.log(activeIDP);

  return (
    <div className="container flex flex-col h-fit mx-auto w-full">
      <div className="flex h-16 mb-2 flex-row justify-between w-full">
        <h2 className="text-xl font-regular self-end text-gray-700 dark:text-gray-200">
          Organizational IDP Metrics:
        </h2>
        <div className="flex flex-row w-3/12 md:w-4/12 items-end justify-end">
          {/* <Button
            text="+ Create IDP"
            bgColor="bg-primary-blue h-[50%]"
            color="white"
            textSize="sm"
            onClick={() => {
              setAddingType("IDP");
              setModalOpen(true);
            }}
          /> */}
          <SelectBasic
            data={idps}
            label="Active IDP"
            defaultValue={activeIDP ? activeIDP.id : null}
            onSelect={(e) => {
              console.log(e.target.value);
              activeIdpMutation.mutate({ idp_id: e.target.value });
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {activeIDP ? (
          <IDPSetupBox
            idp={activeIDP}
            setModalOpen={setModalOpen}
            setAddingObject={setAddingObject}
          />
        ) : (
          <div>Select Active IDP</div>
        )}
      </div>
      <div>
        <h2 className="mt-10 mb-4 text-xl font-normal self-sart text-gray-700 dark:text-gray-200">
          National Policy Metrics:
        </h2>
        <div className="flex flex-row w-full items-center self-center justify-center h-fit md:pl-12">
          <div className="flex flex-row w-full flex-wrap gap-8 items-center justify-center md:items-start md:justify-start">
            {policies && policies.length > 0 ? (
              policies.map((policy, index) => (
                <div className="w-full md:w-[45%]">
                  <CollapsibleMetricTable
                    key={index}
                    data={policy}
                  />
                </div>
              ))
            ) : (
              <div>Add Policies in Master Setup</div>
            )}
          </div>
        </div>
      </div>

      <Modal modalOpen={modalOpen}>
        {addingType == "IDP" ? (
          <CreateIDP setModalOpen={setModalOpen} />
        ) : (
          <div>Nothing to see here</div>
        )}
      </Modal>
    </div>
  );
};

export default Metrics;

import React, { useEffect, useState } from "react";
import { useStateContext } from "application/store/ContextProvider";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { createFinancialYear } from "application/api";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function CreateFinYear({ setModalOpen }) {
  const store = useStateContext();
  const organisation_id = store.user.organisation_id;
  const token = store.user.token;

  const [formData, setFormData] = useState({
    name: null,
    description: null,
    start_date: null,
    end_date: null,
    organisation_id: null,
  });

  const [finYearName, setFinYearName] = useState("Name will be auto generated");

  const makeFinYearName = () => {
    if (formData.start_date === null || formData.end_date === null) return;
    const startYear = formData.start_date.get("year");
    const endYear = formData.end_date.get("year");
    setFinYearName(`${startYear}-${endYear}`);
    setFormData({ ...formData, name: `${startYear}-${endYear}` });
  };

  useEffect(() => {
    makeFinYearName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.start_date, formData.end_date]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(formData);
    createFinancialYear(token, organisation_id, formData, store).then((res) => {
      if (res.status === 200) {
        alert(res.data.message);
        setModalOpen(false);
        return;
      }
      alert(res.response.data.message);
    });
  };

  return (
      <div className="w-[100%] m-12 p-6 bg-white rounded-sm">
        <h2 className="text-2xl text-primary-blue font-medium mb-4">
          Create Financial year
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              disabled={true}
              value={finYearName}
              onChange={handleChange}
              className="w-full p-2 border rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <DatePicker
              id="start_date"
              format="D/MM/YYYY"
              selected={formData.start_date}
              onChange={(date) => {
                setFormData({ ...formData, start_date: date });
                makeFinYearName();
              }}
              label="Start Date"
              maxDate={
                formData.end_date ? formData.end_date.add(-1, "day") : ""
              }
              className="w-full p-2 border rounded-md cursor-pointer"
              required
            />
          </div>
          <div className="mb-4">
            <DatePicker
              id="end_date"
              format="D/MM/YYYY"
              selected={formData.end_date}
              onChange={(date) => {
                setFormData({ ...formData, end_date: date });
                makeFinYearName();
              }}
              minDate={
                formData.start_date
                  ? formData.start_date.subtract(1, "day")
                  : ""
              }
              label="End Date"
              className="w-full p-2 border rounded-md cursor-pointer"
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="hidden"
              id="organisation_id"
              name="organisation_id"
              value={organisation_id}
              onChange={handleChange}
              className="w-full p-2 border rounded-md"
              required
            />
          </div>

          <div className="flex mt-10 flex-row justify-between">
            <div className="flex flex-col justify-end">
              <button
                type="button"
                onClick={() => setModalOpen(false)}
                className="text-gray-500 px-4 text-sm font-light hover:text-red-400"
              >
                Cancel
              </button>
            </div>
            <button
              type="submit"
              className="bg-primary-green text-white font-regular py-1 px-8 rounded hover:bg-primary-green-accent"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
  );
}

export default CreateFinYear;

import React, { useState } from "react";
import { create, useQueryClientData } from "application/api";
import { useStateContext } from "application/store/ContextProvider";
import { TextField } from "@mui/material";
import { useQueryClient, useMutation } from "react-query";
import { SelectMulti, Switch } from "components";

const CreateSDBIP = ({ setModalOpen, idpID }) => {
  console.log("idpId", idpID);
  const store = useStateContext();
  const token = store.user.token;
  const orgID = store.organisation.id;
  const [sdbipName, setSdbipName] = useState("Auto-generated");

  const idps = useQueryClientData("idps");
  const sdbips = useQueryClientData("sdbips");
  const idp = idps.find((item) => item.id === idpID);

  const finYearData = idp.financial_years;

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    short_code: "SDBIP " + (sdbips ? sdbips.length + 1 : 1),
    financial_year_ids: [],
    top_layer: false,
    idp_id: idpID,
  });

  const queryClient = useQueryClient();
  // SDBIP Mutation
  const addSdbipMutation = useMutation(
    () => create("sdbips", token, orgID, formData, store),
    {
      onSuccess: (response) => {
        queryClient.prefetchQuery("sdbips");
        alert("SDBIP created successfully");
        setModalOpen(false);
      },
      onError: (error) => {
        alert("Error creating SDBIP");
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    addSdbipMutation.mutate({ ...formData });
    console.log("formData", formData);
  };

  // Function to handle form field changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleMultiSelectChange = (idsArray) => {
    const sdbipName = makeSdbipName(idsArray);
    setSdbipName(sdbipName);
    setFormData({
      ...formData,
      name: sdbipName,
      financial_year_ids: idsArray,
    });
  };

  const makeSdbipName = (idArray) => {
    let selectedFinYears = finYearData.filter((item) => {
      return idArray.includes(item.id);
    });
    let finYearNames = [];
    selectedFinYears.map((item) => {
      finYearNames.push(...item.name.split("-"));
      return null;
    });
    finYearNames.sort();
    return "SDBIP-" + finYearNames[0] + "-" + finYearNames.at(-1);
  };

  return (
    <div className=" p-10 pb-6 text-left bg-white rounded-md shadow-xl">
      <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white  text-xl bg-primary-blue font-light mb-8">
        Create new <span className="font-semibold">SDBIP</span>
      </h2>
      <form className="mt-16" onSubmit={handleSubmit}>
        {/* Form fields go here */}
        <div className="flex flex-row gap-10 justify-between">
          <div className="mb-8 md:flex-3">
            <TextField
              type="text"
              id="name"
              disabled
              name="name"
              label="SDBIP Name"
              variant="outlined"
              value={sdbipName}
              onChange={handleChange}
              className="w-full p-2 border-2 rounded-md"
              required
            />
          </div>
          <div className="mb-8 md:flex-1">
            <TextField
              type="text"
              id="short_code"
              name="short_code"
              label="Short Code"
              variant="outlined"
              value={formData.short_code}
              onChange={handleChange}
              className="w-full p-2 border-2 rounded-md"
              required
            />
          </div>
        </div>
        <div className="mb-8 w-full">
          <TextField
            type="text"
            sx={{ width: "100%" }}
            id="description"
            name="description"
            value={formData.description}
            label="Description"
            variant="outlined"
            onChange={handleChange}
          />
        </div>

        {finYearData && (
          <div className="mb-8 w-full">
            <SelectMulti
              name="financial_years"
              data={finYearData}
              formData={formData}
              label="Financial Year"
              maxValues={1}
              setFormData={setFormData}
              makeSdbipName={makeSdbipName}
              setSdbipName={setSdbipName}
              handleChange={handleMultiSelectChange}
            />
          </div>
        )}

        <div className="mb-8 w-full flex p-2 gap-4 items-center">
          <span className="block font-light text-gray-600">
            Is this a top layer SDBIP?
          </span>
          <Switch
            label=""
            name="top_layer"
            id="top_layer"
            checked={formData.top_layer}
            onChange={(e) =>
              setFormData({ ...formData, top_layer: e.target.checked })
            }
          />
        </div>

        {/* Submit button */}
        <div className="flex mt-4 flex-row justify-between">
          <div className="flex flex-col justify-end">
            <button
              type="button"
              onClick={() => setModalOpen(false)}
              className="text-gray-500 px-4 text-sm font-light hover:text-red-400"
            >
              Cancel
            </button>
          </div>
          <button
            type="submit"
            className="bg-primary-green text-white font-regular py-1 px-8 rounded hover:bg-primary-green-accent"
          >
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateSDBIP;

import { useStateContext } from 'application/store/ContextProvider';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useApiMutation } from 'application/api';
import { TextField, Button } from '@mui/material';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const CreatePdpItem = ({ setModalOpen, pdp }) => {
    const [pdpFormData, setPdpFormData] = useState({
        pdp_id: pdp.id,
        skills_gap: "Project Management",
        outcome: "Improved Project Management Skills",
        action_plan: "Get project management certification",
        mode_of_learning: "Tertiary Institution",
        practice_opportunity: "Workplace",
        time_frame: "1 year", // change to null for DatePicker
        start_date: null, // change to null for DatePicker
        end_date: null, // change to null for DatePicker
    });
    // skills_gap: "",
    // outcome: "",
    // action_plan: "",
    // mode_of_learning: "",
    // practice_opportunity: "",
    // time_frame: null, // change to null for DatePicker
    // start_date: null, // change to null for DatePicker
    // end_date: null, // change to null for DatePicker

    const { user } = useStateContext();

    const handleChange = (e) => {
        setPdpFormData({
            ...pdpFormData,
            [e.target.name]: e.target.value,
        });
    }

    const addPdpItemMutation = useApiMutation(
        "pdps/item",
        "post",
        user.organisation_id,
        user.token,
        "pdps",
        (response) => {
            alert("Item successfully added to PDP");
            // setModalOpen(false);
        },
        (error) => {
            alert("Error adding PDP Item");
        }
    );

    return (
        <div className="h-fit gap-4 w-[80vw] md:w-[50vw] flex-col bg-white flex justify-center p-10 pt-20 rounded-md items-center">
            <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white text-xl bg-primary-blue font-light mb-8">
                Add new <span className="font-semibold">PDP Item</span>
            </h2>
            <div className="absolute top-0 right-0">
                <Button variant="text" color="error" onClick={() => setModalOpen(false)}>x</Button>
            </div>
            <div className="w-full flex flex-row flex-wrap gap-5">
            {Object.keys(pdpFormData).map((key, index) => (
                key === 'pdp_id' ? (
                    null
                ) :
                key.includes('_date') ? (
                    <DatePicker
                        key={index}
                        name={key}
                        label={(key.charAt(0).toUpperCase() + key.slice(1)).split('_').join(' ')}
                        value={pdpFormData[key]}
                        onChange={(date) => setPdpFormData({ ...pdpFormData, [key]: date })}
                        margin="normal"
                        width={100}
                    />
                ) : (
                    <TextField
                        key={index}
                        type="text"
                        name={key}
                        label={(key.charAt(0).toUpperCase() + key.slice(1)).split('_').join(' ')}
                        onChange={handleChange}
                        value={pdpFormData[key]}
                        fullWidth
                    />
                )
            ))}
            </div>
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    addPdpItemMutation.mutate(pdpFormData);
                }}
                variant="contained"
                color="primary"
                fullWidth
            >
                Add Item
            </Button>
        </div>
    )
}

export default CreatePdpItem;

import React from "react";
import KpiWeightingsTable from "../components/KpiWeightingsTable";
import { useQuery } from "react-query";
import { get } from "application/api";
import { useStateContext } from "application/store/ContextProvider";
import PerformancePlansTable from "../components/PerformancePlansTable";

const PerformancePlans = () => {
  const { user } = useStateContext();
  const {
    isLoading,
    error,
    data: activeIDP,
  } = useQuery("activeIDP", () =>
    get("idps/active", user.organisation_id, null, user.token)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  console.log(activeIDP);

  return (
    <div>
      {activeIDP.sdbips && (
        <PerformancePlansTable
          sdbip={activeIDP.sdbips[0]}
          idp={activeIDP}
        />
      )}
    </div>
  );
};

export default PerformancePlans;

import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AddOutlined } from "@mui/icons-material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useStateContext } from "application/store/ContextProvider";
import { useQuery } from "react-query";
import { get, update } from "application/api";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import CreatePDP from "../modals/CreatePDP";
import CreatePdpItem from "../modals/CreatePdpItem";
// import CreateKPI from "../modals/CreateKPI";


export default function PdpTable({
  showAlignInput = false,
  sdbip,
}) {
  const { brandColors } = useStateContext();
  const { setModalOpen, setModalContent } = useStateContext();
  // react-query get pdps
  const { user } = useStateContext();
  const {
    isLoading,
    error,
    data: pdps,
  } = useQuery("pdps", () =>
      get("pdps", user.organisation_id, { sdbip_id: sdbip.id }, user.token)
  );


  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  if (!pdps.length) {
    return <div>No PDPs found</div>;
  }
  console.log(pdps);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              colSpan={6}
              style={{ backgroundColor: brandColors.blue, color: "white" }}
            >
              <div className="flex flex-row justify-between">
              <Typography variant="h6">
                Personal Development Plans
              </Typography>
              <IconButton
                aria-label="expand row"
                size="small"
                sx={{ ml: 1, color: "white"}}
                onClick={() => {
                  setModalOpen(true);
                  setModalContent(
                  <CreatePDP sdbip={sdbip} setModalOpen={setModalOpen} />
                  );
                }}
              >
                <AddOutlined />
              </IconButton>
              </div>

            </TableCell>
            {/* <TableCell /> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {pdps && pdps.map((pdp) => (
            <Row
              key={pdp.id}
              pdp={pdp}
              sdbip={sdbip}
              showAlignInput={showAlignInput}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row(props) {
  const { pdp } = props;
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { setModalOpen, setModalContent } = useStateContext();

  return (
    <React.Fragment>
      <TableRow >
        <TableCell style={{ width: 20 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" >
          {/* <TableHead>
            <TableRow>
              <TableCell >Ref</TableCell>
              <TableCell >Title</TableCell>
              <TableCell >Employee</TableCell>
              <TableCell >Manager</TableCell>
              <TableCell >Financial year</TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody >
            <TableRow >
              <TableCell sx={{ borderBottom: "unset" }}>{pdp.id}</TableCell>
              <TableCell sx={{ borderBottom: "unset" }}>{pdp.title}</TableCell>
              <TableCell sx={{ borderBottom: "unset" }}>{pdp.employee_name}</TableCell>
              <TableCell sx={{ borderBottom: "unset" }}>{pdp.sdbip_name.substr(6)}</TableCell>
            </TableRow>
          </TableBody>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h7" fontWeight={500}>
                PDP Items
              </Typography>
              <IconButton
                aria-label="expand row"
                size="small"
                sx={{ ml: 1, color: "gray"}}
                onClick={() => {
                  setModalOpen(true);
                  setModalContent(
                  <CreatePdpItem pdp={pdp} setModalOpen={setModalOpen} />
                  );
                }}
              >
                <AddOutlined />
              </IconButton>
              <Table size="small" aria-label="objectives">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>Skills/Performance gap</TableCell>
                    <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>Outcomes Expected</TableCell>
                    <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>Training/Development activity</TableCell>
                    <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>Mode of delivery</TableCell>
                    <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>Time frames</TableCell>
                    <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>Practice opportunity</TableCell>
                    <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>Support person</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pdp.items  && pdp.items.map((pdpItem) => (
                    <TableRow key={pdpItem.id}>
                      <TableCell component="th" scope="row" sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>
                        {pdpItem.skills_gap}
                      </TableCell>
                      <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{pdpItem.outcome}</TableCell>
                      <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{pdpItem.action_plan}</TableCell>
                      <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{pdpItem.mode_of_learning}</TableCell>
                      <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{pdpItem.time_frame}</TableCell>
                      <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{pdpItem.practice_opportunity}</TableCell>
                      <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{pdp.manager_name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    short_code: PropTypes.string.isRequired,
    objectives: PropTypes.string.isRequired,
    parent_id: PropTypes.number.isRequired,
    idp_id: PropTypes.number.isRequired,
  }).isRequired,
};

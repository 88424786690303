import axios from "axios";
import { baseUrl } from "../utils/ENV.js";
// Login

const handleLogin = (email, org_code, password, store) => {
  console.log(email, org_code, password);
  console.log(`${baseUrl}/login`);
  axios
    .get(`${baseUrl}/login`, {
      params: {
        email,
        org_code,
        password,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      console.log("THEN", response);
      if (response.status === 200) {
        store.setUser({
          ...response.data.user,
        });

        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));

        if (response.data.user.active === 1) {
          store.setIsLoggedIn(true);
          store.setIsRegistered(true);
        } else {
          store.setIsLoggedIn(true);
        }
      } else {
        alert("There was an error logging in, please try again.");
      }
    })
    .catch((error) => {
      alert("There was an error logging in");
    });
};

export { handleLogin };

import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AddOutlined } from "@mui/icons-material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useStateContext } from "application/store/ContextProvider";
import { useQuery } from "react-query";
import { get, update } from "application/api";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import CreatePDP from "../modals/CreatePDP";
import CreatePdpItem from "../modals/CreatePdpItem";
import CreateCCR from "../modals/CreateCCR";
import CreateCompetency from "../modals/CreateCompetency";

export default function SetupCoreCompetenciesTable({
  showAlignInput = false,
  activeSDBIP,
}) {
  const { brandColors } = useStateContext();
  const { setModalOpen, setModalContent } = useStateContext();
  // react-query get ccrs
  const { user } = useStateContext();
  const {
    isLoading,
    error,
    data: ccrs,
  } = useQuery("ccrs", () =>
      get("ccrs", user.organisation_id, { sdbip_id: activeSDBIP.id }, user.token)
  );


  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  if (!ccrs) {
    return (
            <div className="flex flex-row gap-4">
              <div>
                No Core Competency Requirements found. Add new
              </div>
              <IconButton
                aria-label="expand row"
                size="small"
                sx={{ mt:-0.5, color: "black"}}
                onClick={() => {
                  setModalOpen(true);
                  setModalContent(
                  <CreateCCR activeSDBIP={activeSDBIP} setModalOpen={setModalOpen} />
                  );
                }}
              >
                <AddOutlined />
              </IconButton>
            </div>
            );
  }

  console.log(ccrs);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              colSpan={6}
              style={{ backgroundColor: brandColors.blue, color: "white" }}
            >
              <div className="flex flex-row justify-between">
                <Typography variant="h6">
                  Employee Core Competency Requirements (CCR)
                </Typography>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  sx={{ ml: 1, color: "white"}}
                  onClick={() => {
                    setModalOpen(true);
                    setModalContent(
                    <CreateCCR activeSDBIP={activeSDBIP} setModalOpen={setModalOpen} />
                    );
                  }}
                >
                  <AddOutlined />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ccrs ? ccrs.map((ccr) => (
            <>
              <Row
                key={ccr.id}
                heading={"Core Managerial Competencies (CMC)"}
                ccrType={"CMC"}
                ccr={ccr}
                sdbip={activeSDBIP}
                showAlignInput={showAlignInput}
                />
              <Row
                key={ccr.id}
                heading={"Core Occupational Competencies (COC)"}
                ccrType={"COC"}
                ccr={ccr}
                sdbip={activeSDBIP}
                showAlignInput={showAlignInput}
              />
            </>
          ))
          : 
          <TableRow>
            <TableCell colSpan={6} align="center">
              No CCRS found
            </TableCell>
          </TableRow>
        }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row(props) {
  const { ccr, ccrType } = props;
  ccr.items = ccr.competencies.filter((item) => item.type == ccrType);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { setModalOpen, setModalContent } = useStateContext();

  return (
    <React.Fragment>
      <TableRow >
        <TableCell style={{ width: 20 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" >
          {props.heading}

          <TableBody >
            <TableRow >
              {/* <TableCell sx={{ borderBottom: "unset" }}>{pdp.id}</TableCell>
              <TableCell sx={{ borderBottom: "unset" }}>{pdp.title}</TableCell>
              <TableCell sx={{ borderBottom: "unset" }}>{pdp.employee_name}</TableCell>
              <TableCell sx={{ borderBottom: "unset" }}>{pdp.sdbip_name.substr(6)}</TableCell> */}
            </TableRow>
          </TableBody>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h7" fontWeight={500}>
                Competencies
              </Typography>
              <IconButton
                aria-label="expand row"
                size="small"
                sx={{ ml: 1, color: "gray"}}
                onClick={() => {
                  setModalOpen(true);
                  setModalContent(
                    <CreateCompetency ccr={ccr} setModalOpen={setModalOpen} />
                  );
                }}
              >
                <AddOutlined />
              </IconButton>
              <Table size="small" aria-label="objectives">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>Competency</TableCell>
                    {ccrType == "CMC" &&  <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>Description</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ccr.items  && ccr.items.map((pdpItem) => (
                    <TableRow key={pdpItem.id}>
                      <TableCell component="th" scope="row" sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>
                        {pdpItem.name}
                      </TableCell>
                      {ccrType == "CMC" &&  <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{pdpItem.description}</TableCell>}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    short_code: PropTypes.string.isRequired,
    objectives: PropTypes.string.isRequired,
    parent_id: PropTypes.number.isRequired,
    idp_id: PropTypes.number.isRequired,
  }).isRequired,
};

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useStateContext } from "application/store/ContextProvider";
import { calculateRatingColor } from "application/utils";
// import MonitorUpdateKPI from "../modals/MonitorUpdateKPI";

const KpiWeightingsTable = ({ idpData, fitView = false, performance_plan }) => {
  const { kpas } = idpData;
  console.log("kpas", kpas);
  const {
    brandPrimaryColors,
    brandColors,
    ratingColors,
    setModalOpen,
    setModalContent,
  } = useStateContext();
  const tableStyle = fitView
    ? { borderRadius: 0, width: "100%" }
    : { borderRadius: 0 };

  const headerCellStyle = {
    backgroundColor: brandPrimaryColors.blue ?? "#000",
    fontWeight: 600,
    fontSize: "1rem",
    color: "#fff",
    p: 2,
  };

  if (!kpas) {
    return <div>Loading...</div>;
  }
  function prepareIndicatorsData({ kpas }) {
    // kpas = kpas.filter((kpa) => kpa.children && kpa.children.length > 0);
    const indicatorsData = [];
    kpas.forEach((kpa) => {
      const { children } = kpa;
      if (!children) {
        console.log("No children");
        return;
      }
      children.forEach((kfa) => {
        const { children } = kfa;
        if (!children) {
          console.log("No children");
          return;
        }

        const kpis = children.filter((child) => child.custodian_id === performance_plan.employee_id);

        kpis.forEach((kpi) => {
          const bgColor = calculateRatingColor(
            kpi.current_value,
            kpi.annual_target,
            ratingColors
          );


          !kpi.weighting && (kpi.weighting = 0);

          indicatorsData.push({
            kfa_short_code: kfa.short_code,
            kpa_short_code: kpa.short_code,
            bgColor,
            ...kpi,
          });
        });
      });
    });
    return indicatorsData;
  }
  const headings = [
    "KPA",
    "KFA",
    "Key Performance Indicator",
    "Base",
    "Annual",
    "Q1",
    "Q2",
    "Q3",
    "Q4",
    "Weighting",
  ];
  const indicatorsData = prepareIndicatorsData({ kpas });
  console.log("indicatorsData", indicatorsData);
  return (
    <div className="mt-8">
      <TableContainer sx={{ overflow: "scroll" }}>
        <Table sx={tableStyle} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={3}
                sx={{
                  ...headerCellStyle,
                  border: 0.5,
                  borderColor: brandColors.lightGray,
                }}
              />
              <TableCell
                colSpan={6}
                align="center"
                sx={{
                  ...headerCellStyle,
                  border: 0.5,
                  borderColor: brandColors.lightGray,
                }}
              >
                Targets
              </TableCell>
              <TableCell sx={{
                  ...headerCellStyle,
                  border: 0.5,
                  borderColor: brandColors.lightGray,
                }}/>
            </TableRow>
            <TableRow>
              {headings.map((heading) => (
                <TableCell
                  align="center"
                  colSpan={1}
                  sx={{
                    ...headerCellStyle,
                    border: 0.5,
                    borderColor: brandColors.lightGray,
                  }}
                >
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderRight: 1, borderColor: "inherit" }}>
            {indicatorsData.map((row) => (
              <TableRow
                key={row.id}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: 600, fontSize: "1rem", border: "solid rgba(224, 224, 224, 1) 1px" }}
                >
                  {row.kpa_short_code}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: 600, fontSize: "1rem", border: "solid rgba(224, 224, 224, 1) 1px" }}
                >
                  {row.kfa_short_code}
                </TableCell>
                <TableCell align="left" sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{row.name}</TableCell>
                <TableCell align="center" sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{row.baseline}</TableCell>
                <TableCell align="center" sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{row.annual_target}</TableCell>
                <TableCell align="center" sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{row.quarter_1_target}</TableCell>
                <TableCell align="center" sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{row.quarter_2_target}</TableCell>
                <TableCell align="center" sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{row.quarter_3_target}</TableCell>
                <TableCell align="center" sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{row.quarter_4_target}</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    // backgroundColor: row.bgColor,
                    border: "solid rgba(224, 224, 224, 1) 1px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModalContent(null);
                    setModalOpen(true);
                    setModalContent(
                      // <MonitorUpdateKPI kpi={row} setModalOpen={setModalOpen} />
                    );
                  }}
                >
                  {row.weighting}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default KpiWeightingsTable;

import React from "react";
import { TextField, Autocomplete } from "@mui/material";

const MultiSelect = ({
  data,
  handleChange,
  defaultValue,
  maxValues,
  limitTags,
  multiple = true,
  ...props
}) => {
  // handle the change event

  return (
    <Autocomplete
      sx={{ width: "100%", maxWidth: 500 }}
      multiple={multiple}
      options={data}
      defaultValue={defaultValue}
      limitTags={limitTags}
      getOptionLabel={(option) => option.name + " " + (option.last_name ?? "")}
      disableCloseOnSelect
      onChange={(event, newValue) => {
        if (multiple){
          const idsArray = newValue.map((item) => item.id);
          handleChange(idsArray);
        }
        else {
          newValue && handleChange(newValue.id);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={`Select ${props.label ?? "Financial Years"}`}
          placeholder={`Select ${props.label ?? "Financial Years"}`}
        />
      )}
    />
  );
};

export default MultiSelect;

import { useStateContext } from 'application/store/ContextProvider';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { get } from 'application/api';
import { useApiMutation } from 'application/api';
import { TextField, Button, Autocomplete } from '@mui/material';
import { SelectBasic, SelectMulti } from 'components/index';

const CreateCompetency = ({ ccr, setModalOpen }) => {
    console.log(ccr)

    const [compentencyFormData, setcompentencyFormData] = useState({
        name: "Moral Competence",
        description: "",
        type: "COC",
        competency_ccr_id: ccr.id,
    });

    const { user } = useStateContext();

    const {
        isLoading,
        error,
        data: departments,
    } = useQuery("departments", () =>
        get("departments", user.organisation_id, null, user.token)
    );

    const addcompentencyMutation = useApiMutation(
        "ccrs/competency",
        "post",
        user.organisation_id,
        user.token,
        "ccrs",
        (response) => {
                alert("compentency successfully created");
                setModalOpen(false);
        },
        (error) => {
            alert("Error adding compentency");
        }
    );


    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>An error has occurred: {error.message}</div>;
    }

    if (!departments.length) {
        return <div>No departments found</div>;
    }


    const handleChange = (e) => {
        setcompentencyFormData({
            ...compentencyFormData,
            [e.target.name]: e.target.value,
        });
    }
    
  return (
    <div className="h-fit gap-4 flex-col bg-white min-w-[40vw] flex justify-center p-10 pt-20 rounded-md items-center">
    <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white text-xl bg-primary-blue font-light mb-8">
            Add <span className="font-semibold">compentency to CCR</span>
    </h2> 
    <div className="absolute top-0 right-0">               
        <Button variant="text" color="error" onClick={() => setModalOpen(false)}>x</Button>
    </div>
    <TextField
        type="text"
        name="name"
        label="Name"
        onChange={handleChange}
        value={compentencyFormData.name}
        fullWidth
    />
    <TextField
        type="text"
        name="description"
        label="Description"
        onChange={handleChange}
        value={compentencyFormData.description}
        fullWidth
    />

    <SelectBasic
        data={[
            { id: "CMC", name: "Core Managerial Competency (CMC)" },
            { id: "COC", name: "Core Operational Competency (COC)" },
        ]}
        label="Type"
        fullWidth={true}
        required={true}
        value={compentencyFormData.type}
        onSelect={(e) => {
            setcompentencyFormData({
                ...compentencyFormData,
                type: e.target.value,
            });
        }}
    />

    <Button
        variant="contained"
        color="primary"
        onClick={() => addcompentencyMutation.mutate(compentencyFormData)}
    >
        Confirm
    </Button>
    </div>
  )
}

export default CreateCompetency
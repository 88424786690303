import { useStateContext } from 'application/store/ContextProvider';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { get} from 'application/api';
import { useApiMutation } from 'application/api';
import { TextField, Button, Autocomplete } from '@mui/material';
import { SelectBasic, SelectMulti } from 'components/index';

const CreatePerformancePlan = ({ setModalOpen, sdbip }) => {

    const [performancePlanFormData, setPerformancePlanFormData] = useState({
        title: "",
        employee_id: "",
        manager_id: "",
        sdbip_id: sdbip.id,
        status: "",
        type: "",
    });

    const { user } = useStateContext();

    const handleChange = (e) => {
        setPerformancePlanFormData({
            ...performancePlanFormData,
            [e.target.name]: e.target.value,
        });
    }

    const {
        isLoading,
        error,
        data: roles,
    } = useQuery("users", () =>
        get("users", user.organisation_id, null, user.token)
    );

    const {
        isIdpLoading,
        errorIdp,
        data: activeIDP,
      } = useQuery("activeIDP", () =>
        get("idps/active", user.organisation_id, null, user.token)
      );

    const addPerformancePlanMutation = useApiMutation(
        "performance_plans",
        "post",
        user.organisation_id,
        user.token,
        "performance_plans",
        (response) => {
                alert("Performance Plan successfully created");
                // setModalOpen(false);
        },
        (error) => {
            alert("Error adding KPI");
        }
    );

    const makeTitle = (employee, manager) => {
        return `${employee} - ${manager} - ${new Date().getFullYear()}`;
    }

    if (isLoading || isIdpLoading || error || errorIdp) {
        return <div>Loading...</div>;
    }
    console.log(activeIDP);

    return (
        <div className="h-fit gap-8 flex-col md:w-[40vw] bg-white flex justify-center p-10 pt-14 rounded-md items-center">
            <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white text-xl bg-primary-blue font-light mb-8">
                    Create new <span className="font-semibold">Performance Plan</span>
            </h2> 
            <div className="absolute top-0 right-0">               
                <Button variant="text" color="error" onClick={() => setModalOpen(false)}>x</Button>
            </div>
            
            <TextField
                type="text"
                name="title"
                label="Title"
                onChange={handleChange}
                value={performancePlanFormData.title}
                fullWidth
                margin="normal"
            />
            <SelectMulti
                sx={{ width: "100%", maxWidth: 500 }}
                data={roles}
                width={"100%"}
                multiple={false}
                label="Employee"
                placeholder="Who's performance_plan is this?"
                limitTags={1}
                handleChange={(e) => {
                    setPerformancePlanFormData({
                        ...performancePlanFormData,
                        employee_id: e,
                    });
                }}
            />
            <SelectMulti
                data={roles}
                multiple={false}
                label="/ Search Manager"
                placeholder="Who's the manager?"
                width={"100%"}
                limitTags={1}
                handleChange={(e) => {
                    setPerformancePlanFormData({
                        ...performancePlanFormData,
                        manager_id: e,
                    });
                }}
            />

            <SelectBasic
                data={activeIDP.sdbips ?? []}
                label="Select SDBIP"
                width={"100%"}
                onSelect={(e) => {
                    setPerformancePlanFormData({
                        ...performancePlanFormData,
                        sdbip_id: e.target.value,
                    });
                }}
            />
            <div className="flex gap-4 flex-row justify-between">
                <SelectBasic
                    data={[
                        { id: "draft", name: "Draft" },
                        { id: "submitted", name: "Submitted" },
                        { id: "approved", name: "Approved" },
                        { id: "rejected", name: "Rejected"}
                    ]}
                    width={150}
                    label="Select Status"
                    onSelect={(e) => {
                        setPerformancePlanFormData({
                            ...performancePlanFormData,
                            status: e.target.value,
                        });
                    }}
                />
                
                <SelectBasic
                    data={[
                        { id: "individual", name: "Individual" },
                        { id: "team", name: "Team" },
                    ]}
                    width={150}
                    label="Select Type"
                    onSelect={(e) => {
                        setPerformancePlanFormData({
                            ...performancePlanFormData,
                            type: e.target.value,
                        });
                    }}
                />
            </div>

                <Button onClick={(e) => {
                    e.preventDefault();
                    addPerformancePlanMutation.mutate(performancePlanFormData);
                    }} 
                    variant="contained" 
                    color="primary"
                    fullWidth
                    >
                    Create performance_plan
                </Button>
        </div>
    )
}

export default CreatePerformancePlan;

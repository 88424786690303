import React, { useState } from "react";
import { TextField, Button, MenuItem } from "@mui/material";
import { SelectMulti, Switch } from "components";
import { useApiMutation, get } from "application/api";
import { useStateContext } from "application/store/ContextProvider";
import { useQueryClient, useQuery } from "react-query";

const CreateKpi = ({ setModalOpen, sdbip, kfaID, kfaName }) => {
  const [kpiFormData, setKpiFormData] = useState({
    name: "",
    short_code: "",
    definition: "",
    objective: "",
    baseline: "",
    current_value: "",
    annual_target: "",
    status: "Draft",
    calculation_type: "Count",
    quarter_1_target: "",
    quarter_2_target: "",
    quarter_3_target: "",
    quarter_4_target: "",
    output_type: "Output",
    current_value: 0,
    unit_of_measurement: "",
    verification_method: "",
    is_top_layer: false,
    sdbip_id: sdbip.id,
    kfa_id: kfaID,
    financial_year_id: sdbip.financial_year_id,
    custodian_id: null,
  });

  const { user } = useStateContext();
  const queryClient = useQueryClient();

  const {
    isLoading,
    error,
    data: roles,
  } = useQuery("roles", () =>
    get("roles", user.organisation_id, null, user.token)
  );

  const addKpiMutation = useApiMutation(
    "kpis",
    "post",
    user.organisation_id,
    user.token,
    "active_sdbip",
    (response) => {
      alert("KPI successfully added to Key Focus Areas");
      setModalOpen(false);
    },
    (error) => {
      alert("Error adding KPI");
    }
  );

  const handleInputChange = (e) => {
    setKpiFormData({
      ...kpiFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleMultiSelectChange = (selectedOptions) => {
    setKpiFormData({
      ...kpiFormData,
      financial_year_ids: selectedOptions.map((option) => option.value),
    });
  };

  const handleSwitchChange = (e) => {
    setKpiFormData({
      ...kpiFormData,
      is_top_layer: e.target.checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to submit the form data, you can use the mutation here
    console.log("kpiFormData", kpiFormData);
    addKpiMutation.mutate({ ...kpiFormData });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  const calculation_types = [
    "Count",
    "Percentage",
    "Ratio",
    "Rate",
    "Decrease",
    "Maximum",
    "Minimum",
  ];
  console.log("roles", roles);

  return (
    <div className="h-fit w-[80vw] flex-col bg-white flex justify-center p-10 rounded-md items-center">
      <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white text-xl bg-primary-blue font-light mb-8">
        Create new <span className="font-semibold">KPI</span> for {kfaName}
      </h2>
      <form className="w-full h-full px-10" onSubmit={handleSubmit}>
        <div className="flex flex-row w-full h-full gap-16 mt-10">
          <div className="flex flex-col w-1/2 gap-8">
            <div className="w-full flex flex-col gap-8">
              <div className="w-full flex flex-row gap-8">
                <TextField
                  type="text"
                  id="name"
                  name="name"
                  label="KPI Name"
                  variant="outlined"
                  value={kpiFormData.name}
                  onChange={handleInputChange}
                  className="w-full p-2 border-2 rounded-md"
                  required
                />
                <TextField
                  type="text"
                  id="short_code"
                  variant="filled"
                  name="short_code"
                  label="Short Code"
                  value={kpiFormData.short_code}
                  onChange={handleInputChange}
                  className="w-full p-2 border-2 rounded-md"
                  required
                />
              </div>
              <TextField
                type="text"
                id="definition"
                name="definition"
                label="Definition"
                variant="outlined"
                value={kpiFormData.definition}
                onChange={handleInputChange}
                className="w-full p-2 border-2 rounded-md"
                required
              />
              <TextField
                type="text"
                id="objective"
                name="objective"
                label="Objective"
                variant="outlined"
                value={kpiFormData.objective}
                onChange={handleInputChange}
                className="w-full p-2 border-2 rounded-md"
                required
              />
              <TextField
                type="text"
                id="verification_method"
                name="verification_method"
                label="Verification Method"
                variant="outlined"
                value={kpiFormData.verification_method}
                onChange={handleInputChange}
                className="w-full p-2 border-2 rounded-md"
                required
              />

              <TextField
                select
                id="calculation_type"
                name="calculation_type"
                label="Calculation Type"
                variant="outlined"
                value={kpiFormData.calculation_type}
                onChange={handleInputChange}
                className="w-1/2 p-2 border-2 rounded-md"
                required
              >
                {calculation_types.map((calculation_type, index) => (
                  <MenuItem key={index} value={calculation_type}>
                    {calculation_type}
                  </MenuItem>
                ))}
              </TextField>
              {/* ... */}
              <div className="mb-8 w-full flex p-2 gap-4 items-center">
                <span className="block font-light text-gray-600">
                  Is this a top layer KPI?
                </span>
                <Switch
                  label=""
                  name="is_top_layer"
                  id="is_top_layer"
                  checked={kpiFormData.is_top_layer}
                  onChange={handleSwitchChange}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/2 gap-10 pt-2">
            <div className="flex w-full flex-row gap-8">
              <TextField
                type="Number"
                id="baseline"
                name="baseline"
                label="Baseline"
                variant="standard"
                value={kpiFormData.baseline}
                onChange={handleInputChange}
                className="w-1/2 p-2 border-2 rounded-md"
              />
              <TextField
                type="Number"
                id="annual_target"
                name="annual_target"
                label="Annual Target"
                variant="standard"
                value={kpiFormData.annual_target}
                onChange={handleInputChange}
                className="w-1/2 p-2 border-2 rounded-md"
              />
            </div>
            <div className="flex w-full flex-row gap-8">
              <TextField
                type="Number"
                id="quarter_1_target"
                name="quarter_1_target"
                label="Quarter 1 Target"
                variant="standard"
                value={kpiFormData.quarter_1_target}
                onChange={handleInputChange}
                className="w-1/2 p-2 border-2 rounded-md"
                required
              />
              <TextField
                type="Number"
                id="quarter_2_target"
                name="quarter_2_target"
                label="Quarter 2 Target"
                variant="standard"
                value={kpiFormData.quarter_2_target}
                onChange={handleInputChange}
                className="w-1/2 p-2 border-2 rounded-md"
                required
              />
            </div>

            <div className="flex w-full flex-row gap-8">
              <TextField
                type="Number"
                id="quarter_3_target"
                name="quarter_3_target"
                label="Quarter 3 Target"
                variant="standard"
                value={kpiFormData.quarter_3_target}
                onChange={handleInputChange}
                className="w-1/2 p-2 border-2 rounded-md"
                required
              />
              <TextField
                type="Number"
                id="quarter_4_target"
                name="quarter_4_target"
                label="Quarter 4 Target"
                variant="standard"
                value={kpiFormData.quarter_4_target}
                onChange={handleInputChange}
                className="w-1/2 p-2 border-2 rounded-md"
                required
              />
            </div>
            <div className="flex flex-row w-full justify-end items-end gap-8">
              <TextField
                type="text"
                id="unit_of_measurement"
                name="unit_of_measurement"
                label="Unit of Measurement"
                variant="outlined"
                value={kpiFormData.unit_of_measurement}
                onChange={handleInputChange}
                className="w-1/2 p-2 border-2 rounded-md"
                required
              />
            </div>
            <div className="flex flex-row w-full justify-end items-end gap-8">
              <TextField
                type="Number"
                id="custodian_id"
                name="custodian_id"
                label="Custodian"
                variant="filled"
                select
                value={kpiFormData.custodian_id}
                onChange={handleInputChange}
                className="w-1/2 p-2 border-2 rounded-md"
                required
              >
                {roles.map((role, index) => (
                  <MenuItem key={index} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-col justify-end">
            <button
              type="button"
              onClick={() => setModalOpen(false)}
              className="text-gray-500 px-4 text-sm font-light hover:text-red-400"
            >
              Cancel
            </button>
          </div>
          <button
            type="submit"
            variant="text"
            className="bg-primary-green text-white font-regular py-1 px-8 rounded hover:bg-primary-green-accent"
          >
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateKpi;

import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AddOutlined } from "@mui/icons-material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useStateContext } from "application/store/ContextProvider";
import { useQuery } from "react-query";
import { get } from "application/api";
import CreateRatingScaleLevel from "../modals/CreateRatingScaleLevel";

export default function SetupRatingScalesTable({
  showAlignInput = false,
  activeSDBIP,
}) {
  console.log(activeSDBIP);
  const { brandColors } = useStateContext();
  const { setModalOpen, setModalContent } = useStateContext();
  // react-query get ccrs
  const { user } = useStateContext();
  const {
    isLoading,
    error,
    data: rating_scales,
  } = useQuery("rating_scales", () =>
      get("rating_scales/employee", user.organisation_id, { sdbip_id: activeSDBIP.id??1 }, user.token)
  );


  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  console.log(rating_scales);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              colSpan={6}
              style={{ backgroundColor: brandColors.blue, color: "white" }}
            >
              <div className="flex flex-row justify-between">
                <Typography variant="h6">
                  Employee Rating Scales
                </Typography>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  sx={{ ml: 1, color: "white"}}
                  onClick={() => {
                    setModalOpen(true);
                    setModalContent(
                    <CreateRatingScaleLevel sdbip={activeSDBIP} setModalOpen={setModalOpen} />
                    );
                  }}
                >
                  <AddOutlined />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rating_scales ? (
            <>
              <Row
                key={rating_scales.id}
                heading={"Rating Scales"}
                rating_scales={rating_scales}
                sdbip={activeSDBIP}
                showAlignInput={showAlignInput}
                />
            </>
          )
          : <TableRow>
              <TableCell colSpan={6} align="center">
                No Rating Scales found
              </TableCell>
            </TableRow>
        }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row(props) {
  const { rating_scales } = props;
  const [open, setOpen] = useState(false);
  const { setModalOpen, setModalContent } = useStateContext();

  return (
    <React.Fragment>
      <TableRow >
        <TableCell style={{ width: 20 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" >
          {props.heading}

          <TableBody >
            <TableRow >
              {/* <TableCell sx={{ borderBottom: "unset" }}>{pdp.id}</TableCell>
              <TableCell sx={{ borderBottom: "unset" }}>{pdp.title}</TableCell>
              <TableCell sx={{ borderBottom: "unset" }}>{pdp.employee_name}</TableCell>
              <TableCell sx={{ borderBottom: "unset" }}>{pdp.sdbip_name.substr(6)}</TableCell> */}
            </TableRow>
          </TableBody>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="objectives">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>level</TableCell>
                    <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>Terminology</TableCell>
                    <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>Description</TableCell>
                    <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>color</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rating_scales && rating_scales.map((rating_scale) => (
                    <TableRow key={rating_scale.id}>
                      <TableCell component="th" scope="row" sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>
                        {rating_scale.level}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>
                        {rating_scale.name}
                      </TableCell>
                      <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px" }}>{rating_scale.description}</TableCell>
                      <TableCell sx={{ border: "solid rgba(224, 224, 224, 1) 1px", backgroundColor: rating_scale.color }}>
                        {/* {rating_scale.color} */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    short_code: PropTypes.string.isRequired,
    objectives: PropTypes.string.isRequired,
    parent_id: PropTypes.number.isRequired,
    idp_id: PropTypes.number.isRequired,
  }).isRequired,
};

import React from 'react';
import './style.css'; // Make sure to import your CSS file
import NavBar from './view_components/components/NavBar';
import { IMAGES } from 'assets';

function ProductLandingPage() {
  return (
    <div  className="scaffold">
      <div id="header">
        <div className="header-bg-design">
          <div className="header-circles-dust"></div>
            <div className="header-circles-big"></div>
            <div className="visa-card">
               
            </div>
        </div>
        <div className="fixed-nav">
          <div className="nav-bg-design">
            <div className="header-circles-dust"></div>
            <div className="header-circles-big"></div>
          </div>
          <div className="nav-container">
            <img id="header-img" src={ IMAGES.logo } alt="logo pocketbank" />
            <nav id="nav-bar">
              <ul>
                <li><a className="nav-link" go="#enim">About</a></li>
                <li><a className="nav-link" go="#tempor">Get in touch</a></li>
                <li><a className="nav-link" go="#minima">Products</a></li>
                <li><a className="faq" go="#">FAQ</a></li>
                <li className="sign-up"><a go="#">Sign Up</a></li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="intro">
          <h1>MANAGEMENT MADE <strong>SIMPLE.</strong></h1>
          <p>We provide the tools to help you manage your business, so you can focus on doing what you love.</p>
          <form action="#" method="GET" id="form">
            <input type="email" name="email" id="email" required placeholder="Enter your email address" />
            <input id="submit" type="submit" value="Get Started" />
          </form>
        </div>
      </div>
        <section className="partners-bar">
          <h3 className="partners">Excepteur sint occaecat</h3>
          <div className="partners-row">
            <svg className="visa" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2103.97 682.57">
              <title>Visa</title>
              <path d="M912.09,671.2H740.41L847.71,11.78h171.7ZM596,11.78,432.31,465.33l-19.37-97.66,0,0L355.2,71.16s-7-59.38-81.44-59.38H3.18L0,23S82.74,40.16,179.58,98.32L328.74,671.21H507.61L780.75,11.78ZM1946.33,671.2H2104L1966.53,11.77h-138c-63.73,0-79.25,49.14-79.25,49.14l-256,610.29h179l35.79-98h218.24l20.11,98ZM1757.42,437.94l90.2-246.77,50.75,246.77ZM1506.65,170.36l24.5-141.61S1455.55,0,1376.74,0c-85.2,0-287.52,37.24-287.52,218.3,0,170.36,237.46,172.47,237.46,262s-213,73.45-283.28,17l-25.53,148.06s76.66,37.23,193.78,37.23,293.9-60.66,293.9-225.76c0-171.45-239.59-187.41-239.59-261.95s167.22-65,240.69-24.5Z"/>
            </svg>
            <svg className="mastercard" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1115.3 552.8">
              <title>Mastercard</title>
              <path d="M635.7,0c-181,0-330,112.7-385.8,271.9H0l12.2,64H269.4l6.4,33.8H0l12.2,64H270L292,552.8H535.4L557,440.7c0,0,0.5-3.5,0.7-5,0.1-0.8,0.2-1.6,0.3-2.4l0.6-3.4h1.2c2.1,3.6,6,7.7,10.9,7.7h54.8l-15.6,110.5h124.7c55.8-159.1,204.8-271.9,385.8-271.9,194,0,352.1,157.9,352.1,352.1S829.7,704,635.7,704c-181,0-330-112.7-385.8-271.9H0L12.2,368H269.4l6.4,33.8H0l12.2,64H270L292,704h554.9l-15.6-110.5h124.7C1094.4,468.1,1243.4,355.3,1424.4,355.3,1618.4,355.3,1876.5,513.2,1876.5,707.4S1618.4,1060,1424.4,1060c-194,0-352.1-157.9-352.1-352.1S1230.4,0,1424.4,0Z"/>
            </svg>
          </div>
        </section>
        <section className="features">
          <h2>Consectetur adipisicing elit</h2>
          <div className="features-row">
            <div className="feature">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>Feature 1</title>
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M22 9.24l-2.52-2.52-1.41 1.41L19.17 9H3v2h16.17l-1.1 1.1 1.41 1.41L22 12.76l-3.54-3.54a.9959.9959 0 0 1 0-1.41L22 9.24zM14 13v3h-4v-3l-4 4 4 4v-3h4v3l4-4-4-4z"/>
              </svg>
              <h3>Dolore magna aliqua</h3>
              <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
            <div className="feature">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>Feature 2</title>
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M19 19H5V5h7V3H5c-1.11 0-2 .89-2 2v14c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/>
              </svg>
              <h3>Quis nostrud exercitation</h3>
              <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            </div>
            <div className="feature">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>Feature 3</title>
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M21.99 11.5c0-2.33-1.91-4.2-4.24-4.2-1.26 0-2.39.55-3.18 1.42l-1.37-1.37-.71.71 1.46 1.46c-1.14 1.55-2.16 3.33-2.64 5.3-.65 2.66.1 5.36 1.93 7.34l-1.42 1.42c-1.79-1.99-2.66-4.68-2.05-7.41.64-2.61 2.54-4.72 5.06-5.41 1.82-.54 3.56-.06 4.89 1.26L19.41 10l.7-.71-1.41-1.41c1.42-1.42 2.24-3.38 2.24-5.46zm-7.66-.02c0 .68.56 1.24 1.24 1.24s1.24-.56 1.24-1.24-.56-1.24-1.24-1.24-1.24.56-1.24 1.24z"/>
              </svg>
              <h3>Consectetur adipiscing elit</h3>
              <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </section>
        <footer>
          <nav>
            <ul>
              <li><a go="#">Contact</a></li>
              <li><a go="#">Privacy</a></li>
              <li><a go="#">Terms</a></li>
            </ul>
          </nav>
        </footer>
    </div>
  );
}

export default ProductLandingPage;

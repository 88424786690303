import React, { useState } from "react";
import { useQuery } from "react-query";
import { get, useApiMutation } from "application/api";
import { useStateContext } from "application/store/ContextProvider";
import { SelectBasic } from "components";
import SetupIndicatorTable from "../components/SetupIndicatorTable";
import { useQueryClientData } from "application/api";

const Indicators = () => {
  const { user } = useStateContext();
  console.log(user);
  const { organisation_id, token } = user;
  console.log("token", token);
  const [activeSDBIP, setActiveSDBIP] = useState({ id: 1, name: "2021/2022" });

  const {
    isLoading,
    error,
    data: activeIDP,
  } = useQuery("activeIDP", () =>
    get("idps/active", organisation_id, null, token)
  );

  const activeIdpMutation = useApiMutation(
    "idps/active",
    "put",
    organisation_id,
    token,
    "activeIDP",
    (response) => {
      console.log("active idp response", response);
    },
    (error) => {
      alert("Update failed!", error);
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  console.log(activeIDP);

  if (!activeIDP) {
    return (
      <div>
        No active IDP selected, please select active IDP in Master Setup, IDP...
      </div>
    );
  }

  return (
    <>
      <div className="flex h-16 mb-2 flex-row justify-between w-full">
        <h2 className="text-xl font-regular self-end text-gray-700 dark:text-gray-200">
          Organizational SDBIP Metrics:
        </h2>
        <div className="flex flex-row w-3/12 md:w-4/12 items-end justify-end">
          <SelectBasic
            data={activeIDP.sdbips}
            label="Select SDBIP"
            defaultValue={activeIDP}
            onSelect={(e) => {
              console.log(e.target.value);
              setActiveSDBIP(
                activeIDP.sdbips.find((s) => s.id === e.target.value)
              );
              console.log(activeSDBIP);

              // activeIdpMutation.mutate({ idp_id: e.target.value });
            }}
          />
        </div>
      </div>
      <SetupIndicatorTable
        kfa={activeIDP.children[0].children[0]}
        sdbip={activeSDBIP}
      />
    </>
  );
};

export default Indicators;

import React from 'react'
import SetupPdpItemsTable from '../components/SetupPdpItemsTable'
import { useQuery } from 'react-query'
import { get } from 'application/api'
import { useStateContext } from 'application/store/ContextProvider'


const SetupPDP = () => {
  const { user } = useStateContext();
  const { organisation_id, token } = user;
  const {
    isLoading,
    error,
    data: activeIDP,
  } = useQuery("activeIDP", () =>
    get("idps/active", organisation_id, null, token)
  );

  return (
    <div className='pt-8'>
        {/* <SetupPdpItemsTable
        pdp={activeIDP ? activeIDP.children[0].children[0] : {}}
        // sdbip={activeSDBIP}
      /> */}
    </div>
  )
}

export default SetupPDP
import React from 'react'
import { Scaffold, TabPager } from "components";
import PerformanceDevelopment from './view_components/tabs/LearningDevelopment';

const tabs = [
    {
        header: { text: "Planning" },
        content: <PerformanceDevelopment />,
    },
    {
        header: { text: "Monitoring & Evaluation" },
        content: <div>Monitoring & Evaluation</div>,
    },
    {
      header: { text: "Reporting" },
      content: <div>Reporting</div>,
    },
    ];
const Learning = () => {
  return (
    <Scaffold moduleTitle="Employee" title="Personal Development">
        <TabPager content={tabs} />
    </Scaffold>
  )
}

export default Learning
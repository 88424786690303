import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function BasicSelect({
  data,
  onSelect,
  defaultValue,
  label,
  placeholder,
  width = "100%",
  ...props
}) {
  return (
    <Box sx={{ width: width }}>
      <FormControl size="small" fullWidth>
        <InputLabel id="simple-select-label">{label}</InputLabel>
        <Select
          labelId="simple-select-label"
          id="simple-select"
          defaultValue={defaultValue ? defaultValue : null}
          placeholder={placeholder ?? label ?? "Select an item"}
          label={label ?? "Select an item"}
          onChange={onSelect}
          fullWidth
          {...props}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {data ? (
            data.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name} {item.last_name ?? ""}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={null}>No List Items</MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
